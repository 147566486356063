import React from "react";
import "./welcome.css"
function Welcome ({onStartClick}){
    return(
        <div className="welcome"><h2>Hari Om,</h2>
<p>
Naamajapa is a powerful way to find spiritual peace, cleanse your mind, and connect with the divine amidst life's distractions. In our busy world, where complex rituals and meditation can be challenging, Naamajapa offers an accessible means for spiritual growth, liberation, divine remembrance, protection, and guidance. Team BookSeva is introducing a simple mobile tool for daily Naamajapa, uniting people worldwide in this devotional practice. Join us in this collective spiritual effort and share it with your friends.</p>      
<h1>Om Namo Narayanaya!</h1>
  <button onClick={onStartClick}>Start</button>
        </div>
    )
}
export default Welcome;