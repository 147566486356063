  import React from 'react';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import './Mode.css';
  import mode5 from './images/mode5.jpeg';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

  function Modes(props) {
    const { isIOS, onmode1, onmode2, onmode3 } = props;
    const handleModeClick = (modeFunction) => {
      if (modeFunction) {
        modeFunction();
      }
    };
    const columnsize = isIOS ? 'col-md-5' : 'col-md-3';
    return (
      <div className="modes container">
        <div className="row">
          <div className={`mode4 card ${columnsize}`}>
            <div className="card-body">
              <div className="buttons-container">
                <button className="btn btn-primary" onClick={onmode1}>
                  <div className="m1">Click to continue with लिख Writing Mode</div>
                  <FontAwesomeIcon icon={faArrowRight} className="modeicon1" />
                </button>
                <h4 className="card-title">
                  <a
                    href="#"
                    className="btn-link"
                    onClick={() => handleModeClick(onmode1)}
                  >
                    When you are writing, engaging the mind, tongue, hands, and eyes with the Mantra minimizes distractions, enhancing concentration and work efficiency. This practice also brings peace by calming the mind, even during moments of disturbance or worry.
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className={`mode4 card ${columnsize}`}>
            <div className="card-body">
              <div className="buttons-container">
                <button className="btn btn-primary" onClick={onmode2}>
                  <div className="m1">Click to continue with शृणु Listening Mode</div>
                  <FontAwesomeIcon icon={faArrowRight} className="modeicon1" />
                </button>
                <h4 className="card-title">
                  <a
                    href="#"
                    className="btn-link"
                    onClick={() => handleModeClick(onmode2)}
                  >
                    The rhythm and the tune of the mantra give you some vibrations in your body and bring peace to the mind, even reducing tension and stress.
                  </a>
                </h4>
              </div>
            </div>
          </div>
          {!isIOS && (
            <div className={`mode4 card ${columnsize}`}>
              <div className="card-body">
                <div className="buttons-container">
                  <button className="btn btn-primary" onClick={onmode3}>
                    <div className="m1">Click to continue with जप Chanting Mode</div>
                    <FontAwesomeIcon icon={faArrowRight} className="modeicon1" />
                  </button>
                  <h4 className="card-title">
                    <a
                      href="#"
                      className="btn-link"
                      onClick={() => handleModeClick(onmode3)}
                    >
                      The vibrations from the mantra stimulate the hypothalamus gland, which regulates various body functions, including immunity and the production of happiness-inducing hormones. As your happiness increases, so does your immunity.
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  export default Modes;
